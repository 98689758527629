export default {
    handleErrosPontoAtendimento(errorData, errorForm) {
        let error = errorData.errors;

        errorForm.name = error.descricao ? error.descricao[0] : null;
        errorForm.type = error.id_tipo_ponto_atendimento ? error.id_tipo_ponto_atendimento[0] : null;
        errorForm.code = error.codigo ? error.codigo[0] : null;
        errorForm.cep = error.cep ? error.cep[0] : null;
        errorForm.address = error.endereco ? error.endereco[0] : null;
        errorForm.number = error.numero ? error.numero[0] : null;
        errorForm.complement = error.complemento ? error.complemento[0] : null;
        errorForm.neighborhood = error.bairro ? error.bairro[0] : null;
        errorForm.city = error.id_cidade ? error.id_cidade[0] : null;
        errorForm.operatingUnit = error.id_unidade ? error.id_unidade[0] : null;
    }
}