<template>
   <b-row>
        <b-col lg="6" sm="12" class="pr-lg-0">
            <!-- //TODO::quando for um usuário de uma unidade operacional esse campo precisa estar bloqueado e já preenchido. -->
            <validation-provider #default="{ errors }" name="operating_unit" rules="required">
                <b-form-group 
                    label="Unidade Operacional*" 
                    label-for="operating_unit"
                    :state="errors.length > 0 || error ? false:null">
                    <v-select 
                        :disabled="selectDisabled" 
                        id="operating_unit"
                        :reduce="(option => option.id_unidade)"
                        :selectable="(option => option.ativo)"
                        v-model="selectedOperatingUnit"
                        :options="operatingUnits"
                        @input="selectUnit"
                        label="descricao_unidade"
                        placeholder="Selecione uma unidade operacional"
                    >
                        <span slot="no-options">Nenhuma opção selecionável.</span>
                    </v-select>
                    <small v-if="errors[0]" class="text-danger">
                        Este campo é de preenchimento obrigatório.
                    </small>
                    <small v-if="error" class="text-danger">
                        {{ error }}
                    </small>
                    <span class="infoInput">É necessário vincular este Ponto de Atendimento com uma Unidade Operacional.</span>
                </b-form-group>
            </validation-provider>
        </b-col>
        <b-col lg="4" sm="12">
            <b-form-group label="Região do DR*" label-for="regiao">
                <b-form-input 
                    id="regiao" 
                    autocomplete="off"
                    v-model="region"
                    disabled
                />
                <span class="infoInput">Região que este Ponto de Atendimento percente</span>
            </b-form-group>
        </b-col>
    </b-row>
</template>

<script>
import { BRow, BCol, BForm, BFormGroup, BFormInput } from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

import vSelect from 'vue-select';

export default {
    components: {
        BRow, BCol, BForm, BFormGroup, BFormInput,
        ValidationProvider, ValidationObserver, vSelect 
    },

    props: {
        error: String,
        preSelected: Number,
        selectDisabled: Boolean,
        edit: {
            default: true,
        }
    },

    data() {
        return {
            selectedOperatingUnit: null,
            region: null,
            operatingUnits: [],
            required
        }
    },

    mounted() {
        this.loadOperatingUnit();
    },

    methods: {
        loadOperatingUnit() {
            this.$http.get(this.$api.unidade(), {
                params: {
                    ativo: this.$props.edit ? true : 'all'
                }
            }).then(({ data }) => {
                this.operatingUnits = data;
            })
        },

        selectUnit(event) {
           this.changeRegion(event);
           this.$emit('selected', event);
        },

        changeRegion (event) {
            if(event && this.operatingUnits.length) {
                this.region = this.operatingUnits.find(unit => {
                    return unit.id_unidade == event
                }).descricao_regiao;
                return;
            }

            this.region = null;
        }
    },

    watch: {
        preSelected(value) {
            this.selectedOperatingUnit = value;
            this.changeRegion(value);
        },
        operatingUnits(value){
            if(value.length){
                this.changeRegion(this.selectedOperatingUnit);
            }
        }
    }
}
</script>

